/* .navbar {
    background-color: #343a40;
}

.navbar-brand,
.nav-link {
    color: #fff !important;
} */

.about_header {
    background: linear-gradient(rgba(0, 0, 0, 0.349), rgba(0, 0, 0, 0.267)), url('https://images.pexels.com/photos/258160/pexels-photo-258160.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1') no-repeat center center;
    background-size: cover;
    height: 600px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about_header .logo img {
    width: 150px;
    height: 150px;
}

.about_header .text h1 {
    font-size: 4rem;
    margin-bottom: 20px;
}

.about_header .text p {
    font-size: 1.5rem;
}

.about_content {
    padding: 50px 0;
}

.about_content h2 {
    margin-bottom: 20px;
}

.team .card {
    border: none;
    margin-bottom: 30px;
}

.team .card img {
    border-radius: 50%;
}

/* .footer {
    background-color: #343a40;
    color: white;
    padding: 20px 0;
    text-align: center;
} */
.about_history,
.about_mission {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.about_history .text,
.about_mission .text,
.about_mission .image,
.about_history .image {
    flex: 1 1 45%;
    margin: 10px;
}

.about_history .image img,
.about_mission .image img {
    width: 100%;
    border-radius: 10px;
}

#conditional_footer_about {
    display: none;
}

@media(max-width:430px) {
    .about_header {
        height: 400px;
    }

    .about_mission {
        margin-top: 3rem;
        flex-direction: column-reverse;
    }

    .about_header .logo img {
        width: 100px;
        height: 100px;
    }

    .about_header .text h1 {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .about_header .text p {
        font-size: 1rem;
    }
}