.white-space-break-spaces {
    white-space: break-spaces;
}
.w-10{
    width: 10%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-40{
    width: 40%;
}
.w-50{
    width: 50%;
}
.w-60{
    width: 60%;
}
.w-70{
    width: 70%;
}
.w-80{
    width: 80%;
}
.w-90{
    width: 90%;
}
.flex-1{
    flex: 1;
}
.flex-10{
    flex: 10%;
}
.flex-20{
    flex: 20%;
}
.flex-30{
    flex: 30%;
}
.flex-40{
    flex: 40%;
}
.flex-50{
    flex: 50%;
}
.flex-60{
    flex: 60%;
}
.flex-70{
    flex: 70%;
}
.flex-80{
    flex: 80%;
}
.flex-90{
    flex: 90%;
}
.flex-100{
    flex: 100%;
}
.font-0_5{
    font-size: 0.5rem !important;
}
.font-0_6{
    font-size: 0.6rem !important;
}
.font-0_7{
    font-size: 0.7rem !important;
}
.font-0_8{
    font-size: 0.8rem !important;
}
.font-0_9{
    font-size: 0.9rem !important;
}
.font-1_25{
    font-size: 1.25rem !important;
}
.font-1_5{
    font-size: 1.5rem !important;
}
.font-1_75{
    font-size: 1.75rem !important;
}
.font-2{
    font-size: 2rem !important;
}
.italic{
    font-style: italic;
}
.underline{
    text-decoration: underline;
}
.underline-red{
    text-decoration: underline;
    text-decoration-color: var(--red_tc);
}
.bold{
    font-weight: bold;
}
.uppercase{
    text-transform: uppercase;
}
.capitalize{
    text-transform: capitalize;
}
.lowercase{
    text-transform: lowercase;
}
.justify{
    text-align: justify;
}
.opacity-0{
    opacity: 0;
}
.opacity-0-5{
    opacity: 0.5 !important;
}
.opacity-1{
    opacity: 1 !important;
}
.pointer{
    cursor: pointer;
}
.disabled{
    background-color: rgba(0,0,0,0.7) !important;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
.overflow-y-hidden{
    overflow-y: hidden;
}
.overflow-hidden{
    overflow: hidden;
}
.text-ellipsis{
    text-overflow: ellipsis;
}
.white-space-nowrap{
    white-space: nowrap;
}
.hide{
    display: none !important;
}
.text-decoration-none{
    text-decoration: none !important;
}
.line-height-1{
    line-height: 1 !important;
}

.bg-green-MS{
    background-color: var( --green_ms) !important;
}
.bg-red-MS{
    background-color: var( --red_ms) !important;
}
.bg_button_ms{
    background-color: var(--bg_button_ms);
}
.text-blue-MS{
    color: var(--blue_ms) !important;
}
.text-red-MS{
    color: var(--red_ms) !important;
}
.text-green-MS{
    color: var(--green_ms) !important;
}

.radius-5 {
    border-radius: 5px;
}
.radius-10 {
    border-radius: 10px;
}
.radius-15 {
    border-radius: 15px;
}



