/* Custom Animation CSS */
.animated {
  animation-duration: 1.5s;
}

.login_panel {
  animation: fadeInUp 1s ease-in-out;
}

.header_logo {
  animation: bounceIn 1.5s ease-in-out;
}

/* .btn {
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.btn:hover {
  background-color: #08adad;
  transform: scale(1.05);
} */

#btn_submit_login {
  transition: background-color 0.3s, transform 0.3s ease-in-out;
}


.text-divider {
  animation: fadeIn 1.5s ease-in-out;
}

#google_sso_btn {
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

#google_sso_btn:hover {
  background-color: #f1c40f;
  transform: scale(1.05);
}

#sso_bar img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}


.container_log {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
}

.container_log_title {
  height: 50px;
}

.log_standard_btn {
  background-color: #45a59d;
}

.background-blur {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  /* background-image: url('https://static.monnaie-services.com/imspass/fond-ecran-cinema-billetterie.webp'); */
  background-position: left;
  background-size: cover;
  filter: blur(0px) opacity(0.9);
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ffffffda, #9ccecaa2, #45a59d63, #9ccecaa2, #ffffffda);
  background-size: 400% 400%;
  animation: gradientAnimation 50s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.login_panel {
  position: relative;
  margin: 0 0vh 0 0;
  background-color: rgba(255, 255, 255, 0.973);
  border: #45a59d52 solid 5px;
  border-radius: 20px;
  padding: 2rem;
  z-index: 3;
  width: 380px;
}

.ms.form.element {
  padding: 10px;
}

.login_panel .btn {
  margin-top: 1rem;
  width: 100%;
}

.text-divider {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;
}

.text-divider::before,
.text-divider::after {
  content: '';
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.text-divider::before {
  margin-right: var(--text-divider-gap);
}

.text-divider::after {
  margin-left: var(--text-divider-gap);
}

.sso_btn img {
  margin: 6px;
  width: 20px;
  height: 20px;
}

.log_input input {
  border: 2px solid #45a59d;
  border-radius: 15px;
}

.log_input input:invalid {
  box-shadow: none;
}

.sso_btn {
  margin-top: 1rem;
  width: 100%;
  display: flex;
}

@media(max-width : 992px) {
  .login_panel {
    position: relative;
    margin: 0 0 0 0;
    background-color: rgba(255, 255, 255, 0.973);
    border: #45a59d solid 5px;
    border-radius: 20px;
    padding: 2rem;
    z-index: 3;
    width: 380px;
  }
}

@media(max-width : 400px) {
  .login_panel {
    border: none;
    border-radius: 0px;
    width: 100%;
  }
}