

.sidebar_accordion .nav-item {
    display: flex;
    padding: 1em 0.5em;
    margin: 0 0.5em;
    border-bottom: 1px solid black;
}

.sidebar_accordion .nav-item .wrap {
    width: 100%;
}

.sidebar_accordion .nav-item .wrap .link {
    color: black;
    text-decoration: none;
    width: 100%;
    padding: 0.5em;
    display: block;
    border-radius: 5px;
}

.sidebar_accordion .nav-item .wrap .link:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.sidebar_accordion .nav-item .wrap i {
    margin: auto;
    padding-right: 15px;
    font-size: 1.25rem;
    width: 40px;
}

.sidebar_accordion .nav-item .wrap .text {
    margin: auto;
    font-size: 1.25rem;
}

.sidebar_accordion .nav-item .wrap .collapse-link {
    color: black;
    text-decoration: none;
    width: 100%;
    padding: 0.5em;
    display: block;
    border-radius: 5px;
}

.sidebar_accordion .nav-item .wrap .collapse-link:hover {
    background-color: rgba(0, 0, 0, 0.15);
    color: black !important;
}

.sidebar_accordion .nav-item .wrap .collapse-link i {
    margin-right: 5px;
    font-size: 1rem;
}

.sidebar_accordion .nav-item .wrap .collapse-text {
    font-size: 1rem;
}

.sidebar_accordion li {
    list-style: none;
}