/* Custom Animation CSS */
.animated {
  animation-duration: 1.5s;
}

.wl_card {
  transition: transform 0.3s ease-in-out;
}

.wl_card:hover {
  transform: translateY(-10px) scale(1.05);
}

.wl_header_title h2 {
  animation-delay: 0.5s;
}

.wl_header_img img {
  animation-delay: 0.5s;
  animation-duration: 2s;
}

.wl_footer_page nav a {
  transition: color 0.3s;
}


/* Ajout du CSS existant */
.wl_btn {
  width: 200px;
  background-color: #45a59d;
  color: white;
  border-radius: 25px;
  transition: all ease-in 0.1s;
  border: none;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
}

.wl_btn:hover {
  background-color: #08adad;
  color: white;
  text-decoration: none;
}

.wl_btn_margin {
  margin-left: 28%;
}

.responsive-text {
  font-size: clamp(0.5rem, 2rem);
}

.wl_header {
  position: relative;
  overflow: hidden;
  height: 70vh;
}

.wl_header_img {
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.wl_header_container_img {
  width: 50%;
}

.wl_header_container_log {
  width: 50%;
}

.wl_container_Img {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: end;
}

.wl_container_Img img {
  height: 70vh;
  z-index: 1;
  width: 68%;
}

.wl_header_img img {
  width: 150px;
  height: 190px;
  object-fit: contain;
  object-position: center;
}

.wl_container_info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.wl_header_info_top {
  height: 20vh;
}

.wl_header_info_bot {
  height: 40vh;
  margin: auto;
  display: block;
}

.wl_header_title {
  width: 32%;
  text-align: end;
}

.wl_header_title span {
  font-size: 25px;
  font-weight: 600;
}

.wl_card {
  width: 15rem;
  border: none;
}

.wl_card_img {
  height: 8rem;
}

.wl_bot_container {
  overflow: hidden;
}

.wl_bot_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.wl_footer_page {
  width: 100%;
  margin: 3rem 0;
  padding: 1rem 0;
}

.wl_footer_page nav {
  height: 100%;
}

.wl_footer_page button {
  width: 200px;
  background-color: #45a59d;
  color: white;
  border-radius: 20px;
  border: none;
  transition: all ease-in 0.2s;
}

.wl_footer_page button:hover {
  background-color: #08adad;
  transform: scale(1.1);
}

.wl_footer_page li a {
  color: black;
}

.wl_footer_page li a:hover {
  color: #45a59d;
}

@media(max-width : 769px) {
  .wl_header {
    height: 60vh;
  }

  .wl_header_container_img {
    width: 20%;
  }

  .wl_header_container_log {
    width: 80%;
  }

  .wl_header_img {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .wl_container_Img {
    width: 100%;
    text-align: center;
  }

  .wl_header_img img {
    width: 100px;
    height: 90px;
    object-fit: contain;
    object-position: center;
  }

  .wl_container_Img img {
    width: 80%;
    height: 45vh;
  }

  .wl_header_info_top {
    height: 20vh;
  }

  .wl_header_info_bot {
    height: 65vh;
    margin: 0;
    padding: 0;
  }

  .wl_header_title {
    width: 100%;
    text-align: start;
    display: flex;
    margin-top: 1rem;
  }

  .wl_header_title h2 {
    margin: 2rem 0 0 0.5rem;
  }

  .wl_btn_margin {
    margin: 2rem 0 2rem auto;
  }
}

@media(max-width : 560px) {
  .wl_header {
    height: 50vh;
  }

  .wl_header_img {
    width: 80px;
    height: 80px;
    text-align: center;
  }

  .wl_header_img img {
    width: 80px;
    height: 90px;
    object-fit: contain;
    object-position: center;
  }

  .wl_container_Img {
    width: 100%;
    text-align: center;
  }

  .wl_container_Img img {
    width: 80%;
    height: 30vh;
    margin-top: 5rem;
  }

  .wl_card {
    width: 10rem;
    border: none;
  }

  .wl_card_img {
    height: 5rem;
  }

  .wl_header_title h2 {
    font-size: 25px;
  }
}

@media(max-width : 450px) {
  .wl_header_title {
    margin: 2rem 0 5rem 0;
  }

  .wl_header_title h2 {
    font-size: 15px;
  }

  .wl_footer_page {
    padding: 1rem 0;
    background-color: #45a59d;
    overflow-x: hidden;
    margin-bottom: 0;
  }

  .wl_footer_page nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    padding: 0;
  }

  .wl_footer_page li {
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
  }

  .wl_footer_page li a {
    color: white;
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0.5rem;
    border-radius: 10px;
  }

  .wl_footer_page li a:hover {
    background-color: white;
    color: black;
  }

  .wl_footer_page button {
    width: auto;
    padding: 0.7rem 1.5rem;
    background-color: #08adad;
    color: white;
    border-radius: 20px;
    border: none;
    transition: all ease-in 0.2s;
    margin-top: 1rem;
  }

  .wl_footer_page button:hover {
    background-color: #06baba;
    transform: scale(1.05);
  }
}

@media(max-width : 376px) {
  .wl_card {
    width: 100%;
    border: none;
  }

  .wl_card_img {
    height: 5rem;
  }

  wl_header a {
    font-size: 9px;
  }
}