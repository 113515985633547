.user_account_icon{
font-size: 200px;
color: white;
}
.acc_container_main{
    display: flex;
    justify-content: center;
    border: double 5px #45a59d;
    margin-bottom: 1rem;
    height: auto;
    background-color: #45a59d;
    overflow: hidden;
}
.acc_container_info{
    width: 50%;
    padding: 0 1rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.acc_container_logo{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    height: auto;
}
.acc_p span{
    color: #45a59d;
}
.acc_p i{
    color: #08adad;
}

@media (max-width:750px) {
    .user_account_icon{
        font-size: 150px;
        }
        .acc_container_main{
            display: flex;
            flex-direction: column;
            border: none;
        }
        .acc_container_info{
            width: 100%;
        }
        .acc_container_logo{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .button-container{
            padding: 0 0 2rem 0;
        }
}