body {
  font-family: "Montserrat", "roboto", sans-serif;
}

.small-font {
  font-size: 12px;
}

.mb_text {
  font-size: 32px;
  margin: 0 0 0 1rem;
  padding: 1rem;
}

.default_height {
  flex: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  /* Masquer la scrollbar pour Firefox */
  -ms-overflow-style: none;
  /* Masquer la scrollbar pour IE et Edge */
}

.default_height::-webkit-scrollbar {
  display: none;
  /* Masquer la scrollbar pour les navigateurs WebKit */
}

.default_color {
  color: #45a59d;
}

.default_bg_color {
  background-color: #45a59d !important;
}

.bg_color_secondary {
  background-color: #519689b7 !important;
}
.bg_warning {
  background-color: #ffeeba;
}
.nouvelle-classe {
  background-color: initial !important;
  border-color: initial !important;
  color: initial !important;
}

/************************************************* Modal */
.modal-header {
  background-color: #45a59d;
  color: white;
  font-size: 20px;
}

.modal-footer button {
  background-color: #45a59d;
  border: none;
  color: white;
}

.modal-body b {
  color: #45a59d;
}

.modal-footer button:hover {
  background-color: #08adad;
  color: white;
}

/************************************************* Global Btn  & input */
.btn_no_rounded {
  width: 200px;
  background-color: #45a59dde;
  color: white;
  border-radius: 10px;
  transition: all ease-in 0.1s;
}

.btn_no_rounded_danger {
  width: 200px;
  background-color: #a54545de;
  color: white;
  border-radius: 10px;
  transition: all ease-in 0.1s;
}
.btn_danger {
  width: 200px;
  background-color: #a54545de;
  color: white;
  border-radius: 25px;
  transition: all ease-in 0.1s;
  padding: 10px 20px;
}

.standard_btn {
  width: 200px;
  background-color: #45a59d;
  color: white;
  border-radius: 25px;
  transition: all ease-in 0.1s;
}
.standard_btn_filter {
  width: 200px;
  background-color: #6c757d;
  color: white;
  border-radius: 25px;
  transition: all ease-in 0.1s;
}

.btn_sm {
  width: 80px;
}

.standard_btn_sm {
  width: auto;
  background-color: #45a59d;
  color: white;
  transition: all ease-in 0.1s;
}

.standard_btn:disabled,
.standard_btn_sm:disabled {
  background-color: #d3d3d3;
  color: #a9a9a9;
  border: 1px solid #d3d3d3;
  cursor: not-allowed;
}

.standard_btn_lg {
  width: 350px;
  background-color: #45a59d;
  color: white;
  border-radius: 25px;
  transition: all ease-in 0.1s;
}

.mob_btn {
  width: 120px;
  background-color: #45a59d;
  color: white;
  border-radius: 25px;
  transition: all ease-in 0.1s;
}

.standard_btn_modal {
  background-color: #45a59d;
  color: white;
  transition: all ease-in 0.1s;
}
.standard_btn_filter:hover {
  opacity: 0.8;
  background-color: #6c757d;
  color: white;
}
.standard_btn:hover,
.btn_no_rounded:hover,
.standard_btn_modal:hover,
.standard_btn_lg:hover,
.standard_btn_sm:hover {
  background-color: #08adad;
  color: white;
}

.btn_no_rounded_danger:hover,
.btn_danger:hover {
  background-color: #a54545b0;
  color: white;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

/* secousse aux boutons standard lors du survol */
.pulse_anim:hover {
  animation: pulse 1s;
}

/* Ajouter une animation de secousse aux boutons de suppression lors du survol */
.delete_btn:hover {
  animation: shake 0.5s;
}

/* Animation pour le bouton standard d'accès */
.standard_access_btn:hover {
  animation: pulse 1s;
}
.voucherBtnInfo {
  background-color: #45a59d;
  border: none;
  color: white;
  transition: 0.2s ease-in;
}

.voucherBtnInfo:hover {
  transform: scale(1.1);
  background-color: #08adad;
}
.voucherBtnInfoWarning {
  background-color: #ffc004;
  border: none;
  color: white;
  transition: 0.2s ease-in;
}

.voucherBtnInfoWarning:hover {
  transform: scale(1.1);
  background-color: #ffdd03;
}
/*****Array Btn  */
.standard_Tab_btn {
  border: none;
  background-color: inherit;
  color: #45a59d;
  transition: all ease-in 0.1s;
}

.standard_Tab_btn:hover {
  transform: scale(1.1);
  background-color: #08adad;
  color: white;
}
.disabled-hover {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

.disabled-hover:hover {
  background-color: inherit !important;
  color: inherit !important;
  border-color: inherit !important;
}
/*****input & Btn  */
.input_style {
  border: 2px solid #45a59d;
  border-radius: 15px;
}

.sideRow {
  width: 50px;
  height: 10px;
  background-color: #45a59d;
  border-radius: 15px;
}

.siderow_md {
  width: 20px;
  height: 5px;
  background-color: #45a59d;
  border-radius: 15px;
}

.gl_container_size {
  height: 80vh;
}

.container_desk_title {
  display: flex;
  align-items: center;
}

/******   Gestion des input de choix des dates via pickaday ****/
.pika-single .pika-table td:hover,
.pika-single .pika-table td:hover .pika-button {
  background-color: #08adad !important;
  color: #ffffff !important;
  border-radius: 3px !important;
}

/* Couleur de la date sélectionnée */
.pika-single .pika-table td.is-selected,
.pika-single .pika-table td.is-selected .pika-button {
  background-color: #45a59d !important;
  color: #ffffff !important;
  border-radius: 3px !important;
}

.pika-single .pika-table td.is-today,
.pika-single .pika-table td.is-today .pika-button {
  color: #45a59d !important;
  border-radius: 3px !important;
}

#dashboard {
  color: #08adad;
}

/************************************************* section & Pane */
section > #pane_container {
  padding: 0;
  height: 85vh;
  margin-left: 30vh;
}

#page_panes {
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
}

#pane_container {
  order: 2;
  flex: 1 1;
}

#pane_container > .pane {
  display: none;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

#page_container > .page {
  display: none;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

header {
  height: 4rem;
  padding: 0;
  background-color: inherit;
  margin-left: 0;
}

.uglyNabar {
  background-color: yellow;
}

.drop_menu {
  border: none;
  background-color: #45a59d;
}

.drop_hov_item {
  color: white;
}

.drop_hov_item:hover {
  background-color: #08adad;
  color: white;
}

.header_container_logo {
  width: 30vh;
  position: fixed;
}

.header_logo {
  width: 50px;
  height: 44px;
  margin: 0.3rem 0.5rem;
}

.header_Rounded_logo {
  background-color: #fdfdfde5;
  margin-top: 2px;
}

.main_btn_menu {
  width: auto;
  margin: 0.3rem 2rem 0 0;
  padding: 0.5rem 1rem;
  background-color: #45a59d;
  color: white;
  border-radius: 25px;
  transition: all ease-in 0.1s;
  border: none;
  z-index: 2000;
}

.container_nav {
  background-color: white;
  width: 30vh;
  height: 100vh;
  z-index: 300;
  position: fixed;
  overflow-y: auto;
}

.sidebar_accordion .nav-item .wrap .link {
  color: black;
  text-decoration: none;
  width: 100%;
  padding: 0.5em;
  display: block;
  border-radius: 0;
  border: none;
  transition: all 0.1s ease;
}

.sidebar_accordion .nav-item .wrap .link:hover {
  background-color: white;
  color: #45a59d;
}

.sidebar_accordion .nav-item {
  border: none;
}

.sidebar {
  border: none;
}

section {
  height: calc(auto - 4rem);
  display: flex;
}

.header_logo {
  cursor: pointer;
}

.order_img_container {
  background-image: url("../img/logo-ims-pass.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 300px;
}

@media (min-width: 768px) {
  .header_logo {
    cursor: default;
  }
}

@media (max-width: 767.98px) {
  .header_container_logo {
    width: 30vh;
    position: relative;
  }

  .header_Rounded_logo {
    margin-top: 2px;
    background-color: #d8dddd75;
  }

  .container_nav {
    display: none !important;
  }

  .container_nav_clone {
    display: block !important;
  }

  .container_desk_title {
    display: flex;
  }
}

@media (max-width: 767.98px) {
  .default_height {
    min-height: auto;
  }

  section > #pane_container {
    margin-left: 0;
  }
}

@media (max-width: 430px) {
  header {
    position: relative;
  }

  section > #pane_container {
    padding: 0;
    height: auto;
    overflow: hidden;
    width: 100%;
  }

  /* ajoute de la hauteur a tous les containers header des pages pour la version mobile */
  .mob-header-ct {
    margin: 1rem 0 0 0;
  }

  .mb_text {
    font-size: 25px;
    margin: 0 0 0 1rem;
    padding: 1rem;
  }

  .default_height {
    overflow: hidden;
    width: 425px;
  }

  .logo_menu_mob {
    animation: pulse infinite 1s;
    /* L'animation 'pulse' avec une durée de 1 seconde en boucle */
  }
}

/************************************************* Side Bar  */

/* .sidebar.show {
    left: 0;
    width: 300px;
} */

/* .sidebar {
    width: 200px;
    overflow-y: auto;
    border-right: 2px solid #45a59d;
    position: absolute;
    left: -50vw;
    width: 0;
    top: 4rem;
    height: calc(100vh - 4rem);
    transition: 0.5s;
    background-color: #fff;
    z-index: 1040;
} */

/* .sidebar_overlay {
    display: none;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: transparent;
    z-index: 1039;
} 

/* *******************************************************footer */

.footerPageBot {
  display: none;
  width: 100%;
  /* Cache le footer par défaut */
}

.activeFooterBot {
  display: block;
  /* Affiche le footer quand la classe activeFooter est ajoutée */
}

.footerPage {
  width: 100%;
  margin: 1rem 0;
}

.footerPage nav {
  height: 100%;
  margin-left: 30vh;
}

.footerPageBot nav {
  height: 100%;
  padding: 2rem 0;
}

.footerPage button,
.footerPageBot button {
  width: 200px;
  background-color: #45a59d;
  color: white;
  border-radius: 20px;
  border: none;
  transition: all ease-in 0.2s;
}

.footerPage button:hover {
  background-color: #08adad;
  transform: scale(1.1);
}

.footerPage li a,
.footerPageBot li a {
  color: black;
}

.footerPage li a:hover,
.footerPageBot li a:hover {
  color: #45a59d;
}

@media (max-width: 767.98px) {
  .footerPage,
  .footerPageBot {
    height: auto;
  }

  .footerPage nav,
  .footerPageBot nav {
    margin-left: 0;
  }
}

#wait_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 1.5em;
  text-align: center;
}

.overlay_logo {
  width: 150px;
  height: 150px;
}

@media (max-width: 430px) {
  .footerPage {
    padding: 1rem 0;
    background-color: #45a59d;
    overflow-x: hidden;
    margin-bottom: 0;
    /* Couleur de fond pour se démarquer */
  }

  .footerPage nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    /* Ajustement pour les petits écrans */
    padding: 0;
  }

  .footerPage li {
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
  }

  .footerPage li a {
    color: white;
    font-size: 1rem;
    /* Taille de police ajustée pour la lisibilité */
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0.5rem;
    /* Couleur d'arrière-plan des liens */
    border-radius: 10px;
  }

  .footerPage li a:hover {
    background-color: white;
    color: black;
    /* Effet hover pour les liens */
  }

  /* Bouton "nous contacter" */
  .footerPage button {
    width: auto;
    padding: 0.7rem 1.5rem;
    background-color: #08adad;
    color: white;
    border-radius: 20px;
    border: none;
    transition: all ease-in 0.2s;
    margin-top: 1rem;
  }

  .footerPage button:hover {
    background-color: #06baba;
    transform: scale(1.05);
  }
}

@media (max-width: 375px) {
  .default_height {
    width: 375px;
  }
}
