/* 
.ms.form.row:not(.first) {
    margin-top: 0.2rem;
}
 */

.ms.form.element input:invalid {
  box-shadow: 0 0 5px 1px red;
}

.ms.form.element input:focus:invalid {
  box-shadow: 0 0 5px 1px orange;
}

/*
Separators
*/

.text-divider {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: silver;
}

.text-divider::before {
  content: '';
  margin-top: 0.2rem;
  margin-right: 0.5rem;
  height: 2px;
  background-color: silver;
  flex-grow: 1;
}

.text-divider::after {
  content: '';
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  height: 2px;
  background-color: silver;
  flex-grow: 1;
}

input[type="email"] {
  text-transform: lowercase;
}

/*
color_field
*/
.color_field input.unset {
  border-width: 0px;
}

.color_field input.set {
  border-width: 2px;
}

.color_field svg {
  height: 1em;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
}

.color_field label {
  margin-right: 0.2rem;
}