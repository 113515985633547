.conditional_content {
    display: flex;
}

.mention_content {
    width: 90%;
}

.conditional_side_nav {
    width: 50px;
    margin: 0 0 0 20px;
    height: 100vh;
    position: sticky;
    top: 0;
    bottom: 0;
    width: apx;
    overflow-y: auto;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#conditional_footer_legal {
    display: none;
}

#page_notices {
    overflow: auto;
}