/* Material Icons */

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}

/* Main layout  */

body,
#page_container {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    overflow: auto;
}


/* API wait screen */
.wait_overlay {
    display: none;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: transparent;
    z-index: 9999;
}

.wait_overlay:hover {
    cursor: url("/assets/gears.svg"), pointer;
}


.clipboard_hover:hover {
    color: blue;
    text-decoration: underline;
    cursor: url("/assets/copy_cursor.svg"), pointer;
}